.ProseMirror {
  counter-reset: czi-counter-0;
  counter-reset: czi-counter-1;
  counter-reset: czi-counter-2;
  counter-reset: czi-counter-3;
  counter-reset: czi-counter-4;
  counter-reset: czi-counter-5;
  counter-reset: czi-counter-6;
  counter-reset: czi-counter-7;
  --tab-width: 24px;

  ol,
  ul {
    /*
    * Use `column-span: all` and `display: flow-root` to force formatting context
    * https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Block_formatting_context
    */
    column-span: all;
    counter-reset: none;
    display: flow-root;
    list-style-type: none;
    margin: 0;
    margin-block-end: 0;
    margin-block-start: 0;
    padding: 0;
  }

  /* bullet indentation */
  li > span > p {
    // margin: 0;
    // margin-block-end: 0;
    // margin-block-start: 0;
    padding-left: var(--tab-width);
  }

  /* https://css-tricks.com/numbering-in-style/ */
  ul li > span > p:first-child::before {
    color: var(--czi-list-style-color);

    /* content: '\2022'; */
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 1.5em;
    line-height: 1em;
    margin-left: calc(-1 * var(--tab-width));
    position: absolute;
    text-align: center;
    top: 2px;
    width: var(--tab-width);
  }

  ol {
    /* counter-reset: czi-counter;
    list-style-type: none; */
    --czi-counter-name: czi-counter-0;
    --czi-list-style-type: decimal;
    --czi-counter-reset: 0;

    counter-reset: none;
  }

  ol > li {
    counter-increment: var(--czi-counter-name);
  }

  ol:not([data-counter-reset='none']) {
    counter-reset: var(--czi-counter-name) var(--czi-counter-reset);
  }

  ol[data-counter-reset='none'] {
    counter-reset: none;
  }

  ol > li > span > p:first-child::before {
    /*
    * Note that this CSS Rule will not work in MS-Edge 15:
    * - MS-Edge 15 does not supported using CSS variable isnide pseudo element
    *   https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11495448/
    * - MS-Edge 15 does not supported nested CSS fucntions.
    *   https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/18936010/
    * In order to support MS-Edge 15, we'd have to use extra rules.
    * See the section "MS-Edge 15 Rules" below.
    */
    content: counter(var(--czi-counter-name), var(--czi-list-style-type)) '. ';
    color: var(--czi-list-style-color);
    display: inline-block;
    font-size: 1em;
    left: -5px;
    line-height: 1em;
    margin-left: -24pt;
    position: relative;
    text-align: right;
    top: 0;
    width: 24pt;
  }

  ul[data-indent='7'] li > span > p:first-child::before,
  ul[data-indent='4'] li > span > p:first-child::before,
  ul[data-indent='1'] li > span > p:first-child::before {
    content: '◦'; /* circle */
  }

  ul[data-indent='5'] li > span > p:first-child::before,
  ul[data-indent='2'] li > span > p:first-child::before {
    content: '▪'; /* square */
  }

  ul[data-indent='6'] li > span > p:first-child::before,
  ul[data-indent='3'] li > span > p:first-child::before,
  ul[data-indent='0'] li > span > p:first-child::before {
    content: '•'; /* circle */
  }

  /* To do lists */

  li[data-bangle-is-todo] {
    display: flex;
    flex-direction: row;
    position: relative;
    & > span:first-child > input {
      margin-top: 0.5rem;
      margin-right: 6px;
      outline: none;
      position: absolute;
    }
    & > span > p:first-child::before {
      display: none;
    }
  }
}
