.ProseMirror {
  /* paragraph spacing */
  > p {
    padding-bottom: 3px;
    padding-top: 3px;
  }

  li > span > p {
    padding-bottom: 3px;
    padding-top: 3px;
  }
  /** Tab indent **/
  :global(.charm-tab) {
    tab-size: var(--tab-width); // should equal list item padding

    &[data-indent='1'] {
      tab-size: calc(2 * var(--tab-width));
    }
    &[data-indent='2'] {
      tab-size: calc(3 * var(--tab-width));
    }
    &[data-indent='3'] {
      tab-size: calc(4 * var(--tab-width));
    }
    &[data-indent='4'] {
      tab-size: calc(5 * var(--tab-width));
    }
    &[data-indent='5'] {
      tab-size: calc(6 * var(--tab-width));
    }
    &[data-indent='6'] {
      tab-size: calc(7 * var(--tab-width));
    }
  }

  /** hide tab when a user suggested to delete a list item - it should not exist yet **/
  li :global(.insertion > .charm-tab) {
    display: none;
  }
}
